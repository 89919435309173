<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('external_sidebar.nomination_letter')}} {{$t('globalTrans.search')}}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col sm="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            disabled
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('external_sidebar.nomination_letter')}} {{$t('globalTrans.list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" @click="pdfExport" class="mr-2">
                          {{  $t('globalTrans.export_pdf') }}
                        </b-button> -->
                        <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table  thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(batch_no)="data">
                                          {{ $n(data.item.batch_no) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(application_deadline)="data">
                                          <span v-if="data.item.application_deadline" class="capitalize">{{ data.item.application_deadline | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(training_start_date)="data">
                                          <span class="capitalize">{{ data.item.training_start_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(training_end_date)="data">
                                          <span class="capitalize">{{ data.item.training_end_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button class="mr-1 mt-1 mb-1" title="View Details" v-b-modal.modal-5 variant="info" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i> {{ $t('globalTrans.view')}}</b-button>
                                            <template v-if="data.item.status === 1">
                                              <span v-if="data.item.hasApplied === 1">
                                                <b-button disabled variant="success" class="mt-1" title="Applied" size="sm">{{ $t('externalTraining.applied')}}</b-button>
                                                <b-button class="mr-1 mt-1" title="Application Details" v-b-modal.modal-6 variant="primary" size="sm" @click="details(data.item)">{{ $t('externalTraining.apply_details')}}</b-button>
                                              </span>
                                              <span v-else-if="data.item.hasApplied == 0 && (!(data.item.application_deadline) || data.item.application_deadline >= applicationDeadline)">
                                                <b-button v-b-modal.modal-4 variant=" btn-primary" size="sm" @click="edit(data.item)">{{ $t('externalTraining.apply')}}</b-button>
                                              </span>
                                            </template>
                                            <template v-else>
                                              <b-button disabled variant=" btn-warning" size="sm">{{ $t('globalTrans.close')}}</b-button>
                                            </template>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
                <!-- <pre>{{ $store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList }}</pre> -->
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('external_sidebar.nomination_letter') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :item="item" :key="id" ref="details"/>
        </b-modal>
        <b-modal id="modal-6" size="lg" :title="$t('externalTraining.apply_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <DetailsApplication :item="item" :key="id" ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularPublicationToggleStatus, trainingAppliedList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Form from './Form'
import Details from './Details'
import DetailsApplication from './DetailsApplication'
import { mapGetters } from 'vuex'
import moment from 'moment'
const today = new Date().toISOString().substr(0, 10)

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details, DetailsApplication
    },
    data () {
        return {
            search: {
                // applied_org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
                org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
                fiscal_year_id: []
            },
            certiificateList: [],
            applicationDeadline: today,
            id: 0,
            item: '',
            appliedList: []
        }
    },
    computed: {
      ...mapGetters({
          authUser: 'Auth/authUser'
      }),
      batchList () {
        const batchList = [
            { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
            { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
            { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
            { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
            { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' }
        ]
        return batchList
      },
      accommodationList () {
          const accommodations = [
              { text: this.$i18n.locale === 'en' ? 'With Accommodation' : 'থাকার ব্যবস্থা সহ', text_en: 'With Accommodation', text_bn: 'থাকার ব্যবস্থা সহ', value: 1 },
              { text: this.$i18n.locale === 'en' ? 'Without Accommodation' : 'থাকার ব্যবস্থা ছাড়া', text_en: 'Without Accommodation', text_bn: 'থাকার ব্যবস্থা ছাড়া', value: 2 }
          ]
          return accommodations
      },
      fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        if (this.$store.state.ExternalUserTraining.trainerOrTrainee) {
          // return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0 && (item.value === 12 || item.value === this.$store.state.ExternalUserTraining.trainerOrTrainee.professional_info.org_id))
          return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg)
        } else {
          return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
        }
      },
      trainerEvaluationList: function () {
        return this.$store.state.ExternalUserTraining.traineeTranerObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('externalTraining.training_application')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
              { label: this.$t('elearning_config.fiscal_year'), class: 'text-left' },
              { label: this.$t('elearning_config.organization'), class: 'text-left' },
              { label: this.$t('elearning_config.training_title'), class: 'text-left' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-left' },
              // { label: this.$t('elearning_iabm.application_deadline'), class: 'text-left' },
              { label: this.$t('elearning_iabm.training_start_date'), class: 'text-left' },
              { label: this.$t('elearning_iabm.training_end_date'), class: 'text-left' },
              // { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'circular_memo_no' },
              { key: 'fiscal_year_bn' },
              { key: 'org_bn' },
              { key: 'training_title_bn' },
              { key: 'batch_name_bn' },
              // { key: 'application_deadline' },
              { key: 'training_start_date' },
              { key: 'training_end_date' },
              // { key: 'status' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'circular_memo_no' },
              { key: 'fiscal_year' },
              { key: 'org' },
              { key: 'training_title' },
              { key: 'batch_name' },
              // { key: 'application_deadline' },
              { key: 'training_start_date' },
              { key: 'training_end_date' },
              // { key: 'status' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
      this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        async appliedListData () {
          RestApi.getData(trainingElearningServiceBaseUrl, trainingAppliedList, '').then(response => {
            if (response.success) {
              this.appliedList = response.data
            } else {
              this.appliedList = []
              if (!response.profileComplete) {
                this.$router.push('/trainee-trainer-panel/profile')
              }
            }
          })
        },
        dataChange () {
            this.loadData()
        },
        details (item) {
            this.item = item
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, circularPublicationToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
        },
        async loadData () {
          this.appliedListData()
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          RestApi.getData(trainingElearningServiceBaseUrl, 'external-user/circular-publications', params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
          const listData = data.map(item => {
            const applied = this.appliedList.find(list => String(list.circular_id) === String(item.id))
            const applyData = {}
            if (typeof applied === 'undefined') {
              applyData.hasApplied = 0
              applyData.accommodation_type = ''
              applyData.accommodation_type_bn = ''
              applyData.reason = ''
              applyData.reason_bn = ''
              applyData.attend = 0
              applyData.attend_details = ''
              applyData.number_attend = ''
            } else {
              applyData.hasApplied = 1
              const accoObj = this.accommodationList.find(acco => acco.value === parseInt(applied.accommodation_type))
              applyData.accommodation_type = accoObj.text_en
              applyData.accommodation_type_bn = accoObj.text_bn
              applyData.reason = applied.reason
              applyData.reason_bn = applied.reason_bn
              applyData.attend = applied.attend
              applyData.attend_details = applied.attend_details
              applyData.number_attend = applied.number_attend
            }
            const isBefore = this.appliedList.find(list => parseInt(list.training_category_id) === parseInt(item.training_category_id))
            if (typeof isBefore !== 'undefined') {
              var years = moment().diff(isBefore.created_at, 'years', false)
              if (years > 2) {
                applyData.hasApplied = 1
                applyData.accommodation_type = ''
                applyData.accommodation_type_bn = ''
                applyData.reason = ''
                applyData.reason_bn = ''
                applyData.attend = 0
                applyData.attend_details = ''
                applyData.number_attend = ''
              }
            }
            const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }

            const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office = officeObj.text_en
              officeData.office_bn = officeObj.text_bn
            } else {
              officeData.office = ''
              officeData.office_bn = ''
            }
            const officeTypeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
            const officeTypeData = {}
            if (typeof officeTypeObj !== 'undefined') {
              officeTypeData.office_type = officeTypeObj.text_en
              officeTypeData.office_type_bn = officeTypeObj.text_bn
            } else {
              officeTypeData.office_type = ''
              officeTypeData.office_type_bn = ''
            }

            const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalYearData = {}
            if (typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }
             const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
            const trainingTitleData = {}
            if (typeof trainingTitleObj !== 'undefined') {
              trainingTitleData.training_title = trainingTitleObj.text_en
              trainingTitleData.training_title_bn = trainingTitleObj.text_bn
            } else {
              trainingTitleData.training_title = ''
              trainingTitleData.training_title_bn = ''
            }
            const trainingTypeObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
            const trainingTypeData = {}
            if (typeof trainingTypeObj !== 'undefined') {
              trainingTypeData.training_type = trainingTypeObj.text_en
              trainingTypeData.training_type_bn = trainingTypeObj.text_bn
            } else {
              trainingTypeData.training_type = ''
              trainingTypeData.training_type_bn = ''
            }
            const trainingCategoryObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
            const trainingCategoryData = {}
            if (typeof trainingCategoryObj !== 'undefined') {
              trainingCategoryData.training_category = trainingCategoryObj.text_en
              trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
            } else {
              trainingCategoryData.training_category = ''
              trainingCategoryData.training_category_bn = ''
            }
            const batchObj = this.batchList.find(doc => doc.value === parseInt(item.batch_no))
            const batchData = {}
            if (typeof batchObj !== 'undefined') {
              batchData.batch_name = batchObj.text_en
              batchData.batch_name_bn = batchObj.text_bn
            } else {
              batchData.batch_name = ''
              batchData.batch_name_bn = ''
            }

            return Object.assign({}, item, orgData, officeData, officeTypeData, fiscalYearData, trainingTitleData, applyData, trainingTypeData, trainingCategoryData, batchData)
          })
          return listData
        }
        // pdfExport () {
        //   const reportTitle = this.$i18n.locale === 'en' ? 'Circular Publication' : 'বিজ্ঞপ্তি প্রকাশ'
        //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this)
        // }
    }
}
</script>
