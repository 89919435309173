<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(createData)" @reset.prevent="reset">
                  <!-- <b-row>
                    <b-col sm="9">
                      <ValidationProvider name="Accommodation Type" vid="accommodation_type" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="accommodation_type"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('externalTraining.accommodation')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-radio-group
                            id="accommodation_type"
                            style="margin-top: 6px"
                            v-model="formData.accommodation_type"
                            :options="options"
                            name="accommodation_type"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-radio-group>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row> -->
                  <b-row>
                    <b-col sm="4">
                      <ValidationProvider name="Organization"  vid="org_id" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.organization')}}
                          </template>
                          <b-form-select
                              disabled
                              plain
                              v-model="formData.org_id"
                              :options="orgList"
                              id="org_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Office Type" vid="office_type_id" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="office_type_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.office_type')}}
                          </template>
                          <b-form-select
                              disabled
                              plain
                              v-model="formData.office_type_id"
                              :options="officeTypeList"
                              id="office_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Office" vid="office_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="office_id"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('globalTrans.office')}}
                            </template>
                            <b-form-select
                              disabled
                              plain
                              v-model="formData.office_id"
                              :options="officeList"
                              id="office_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Training Type"  vid="training_type_id" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="training_type_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_type')}}
                          </template>
                          <b-form-select
                            disabled
                            plain
                            v-model="formData.training_type_id"
                            :options="trainingTypeList"
                            id="training_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Training Category" vid="training_category_id" rules="">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="training_category_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            disabled
                            plain
                            v-model="formData.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Training Title" vid="training_title_id">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="training_title_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            disabled
                            plain
                            v-model="formData.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Batch NO" vid="batch_no" rules="">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="batch_no"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_iabm.batch_no')}}
                          </template>
                          <b-form-input
                            disabled
                            v-model="formData.batch_no"
                            id="batch_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Registration" vid='circular_memo_no' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="circular_memo_no"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                                {{ $t('externalTraining.circular_memo_no') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                disabled
                                id="circular_memo_no"
                                v-model="formData.circular_memo_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Name" vid='name' rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                                {{ $t('user.namel')}}
                            </template>
                            <b-form-input
                                id="name"
                                disabled
                                v-model="formData.name"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="NID" vid='nid' rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="nid"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                                {{ $t('externalUserIrrigation.nid')}}
                            </template>
                            <b-form-input
                                disabled
                                type="number"
                                id="nid"
                                v-model="formData.nid"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Date Of Birth" vid="date_of_birth" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="date_of_birth"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('externalUserIrrigation.date_of_birth')}}
                            </template>
                            <b-form-input
                            disabled
                            class="fromDate"
                            v-model="formData.date_of_birth"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Email" vid='email' rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="email"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                                {{ $t('externalTraining.email')}}
                            </template>
                            <b-form-input
                                disabled
                                id="email"
                                v-model="formData.email"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="12" xs="12" v-if="formData.gender === 2">
                      <b-form-checkbox
                        class="mt-2"
                        id="attend"
                        v-model="formData.attend"
                        :value=1
                        :unchecked-value=0
                      >
                        {{$t('externalTraining.attend')}}
                      </b-form-checkbox>
                    </b-col>
                    <b-col sm="6" v-if="formData.attend === 1">
                      <ValidationProvider name="Number Of Attend" vid='number_attend' rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="mobile"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalTraining.number_attend')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            id="number_attend"
                            v-model="formData.number_attend"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="6" v-if="formData.attend === 1">
                      <ValidationProvider name="Number Of Attend" vid='attend_details'>
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="attend_details"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalTraining.attend_details')}}
                          </template>
                          <b-form-textarea
                            id="attend_details"
                            v-model="formData.attend_details"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                      <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="remarks"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.remarks_en')}}
                          </template>
                          <b-form-textarea
                              rows="2"
                              id="remarks"
                              v-model="formData.remarks"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                      <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="remarks_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.remarks_bn')}}
                          </template>
                          <b-form-textarea
                              rows="2"
                              id="remarks_bn"
                              v-model="formData.remarks_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="text-right my-2">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    <b-button variant="danger" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{formData}}</pre> -->
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingApplicationStore, profileInfo } from '../../api/routes'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    const tmp = this.getFormData()
    // this.formData = tmp
    this.getUserInfo(tmp)
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('externalTraining.apply') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        attend: 0,
        number_attend: '',
        attend_details: '',
        accommodation_type: 1,
        mobile: '',
        training_type_id: 0,
        training_category_id: 0,
        training_title_id: 0,
        office_type_id: 0,
        date_of_birth: '',
        batch_no: '',
        org_id: 0,
        office_id: 0,
        nid: '',
        name: '',
        email: '',
        gender: '',
        circular_memo_no: '',
        remarks: '',
        remarks_bn: '',
        status: 1
      },
      options: [
        { text: this.$i18n.locale === 'en' ? 'With Accommodation' : 'থাকার ব্যবস্থা সহ', value: '1' },
        { text: this.$i18n.locale === 'en' ? 'Without Accommodation' : 'থাকার ব্যবস্থা ছাড়া', value: '2' }
      ],
      officeTypeList: [],
      officeList: [],
      trainingCategoryList: [],
      loading: false,
      trainingTitleList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.filter(item => item.status === 1)
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    getUserInfo (tmp) {
      this.loading = true
      RestApi.getData(trainingElearningServiceBaseUrl, `${profileInfo}`).then(response => {
        if (response.success) {
          tmp.date_of_birth = response.data.date_of_birth
          tmp.nid = response.data.nid
          tmp.name = response.data.name
          tmp.email = response.data.email
          tmp.gender = response.data.gender
          tmp.mobile = response.data.mobile
          tmp.registration_for = this.$store.state.ExternalUserIrrigation.panelObj.trainingType
        } else {
          tmp.date_of_birth = ''
          tmp.nid = ''
          tmp.name = ''
          tmp.email = ''
          tmp.gender = ''
          tmp.mobile = ''
          tmp.registration_for = 0
        }
        tmp.accommodation_type = 1
        this.formData = tmp
        this.loading = false
      })
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    async createData () {
      this.loading = true
      let result = null
      const loadinState = { loading: false, listReload: false }
      var formData = new FormData()
      Object.keys(this.formData).map(key => {
        formData.append(key, this.formData[key])
      })
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingApplicationStore, formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingApplicationStore, formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.$socket.emit('send-notification', result.notification)
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
